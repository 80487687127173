.loaderbox {
	display: inline-block;
	width: 100%;
	height: 100vh;
	// border: 1px solid currentcolor;
	border-radius: 3px;
	font-size: 30px;
	padding: 1em;
	position: fixed;
	margin-bottom: 0.25em;
	vertical-align: top;
	transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
	text-align: center;
	z-index: 2999;
}

[class*='loader-'] {
	display: inline-block;
	width: 10em;
	height: 10em;
	color: inherit;
	vertical-align: middle;
	pointer-events: none;
	z-index: 3000;
}

////////////////////////////////////////////
.loader-37 {
	border-right: 0.1em solid currentcolor;
	border-radius: 100%;
	animation: loader-37 800ms linear infinite;

	&:before,
	&:after {
		content: '';
		width: 0.8em;
		height: 0.8em;
		display: block;
		position: absolute;
		top: calc(50% - 0.4em);
		left: calc(50% - 0.4em);
		border-left: 0.08em solid currentcolor;
		border-radius: 100%;
		animation: loader-37 400ms linear infinite reverse;
	}

	&:after {
		width: 0.6em;
		height: 0.6em;
		top: calc(50% - 0.3em);
		left: calc(50% - 0.3em);
		border: 0;
		border-right: 0.05em solid currentcolor;
		animation: none;
	}
}

@keyframes loader-37 {
	from {
		transform: rotate(360deg);
	}

	to {
		transform: rotate(0deg);
	}
}

//////////////////////
.loaderbox:nth-of-type(n + 43) {
	display: none;
}