@font-face {
	font-family: 'icomoon';
	src: url('./icomoon.eot?fldtto');
	src: url('./icomoon.eot?fldtto#iefix') format('embedded-opentype'),
		url('./icomoon.ttf?fldtto') format('truetype'),
		url('./icomoon.woff?fldtto') format('woff'),
		url('./icomoon.svg?fldtto#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-live_tracker_icon:before {
	content: '\e943';
	color: #fcfcfc;
}
.icon-icon_unassigned:before {
	content: '\e900';
	color: #fff;
}
.icon-icon_service:before {
	content: '\e901';
	color: #fff;
}
.icon-icon_category:before {
	content: '\e902';
	color: #fff;
}
.icon-icon_redflag:before {
	content: '\e903';
	color: #fff;
}
.icon-icon_garage:before {
	content: '\e904';
	color: #fff;
}
.icon-icon_region:before {
	content: '\e905';
	color: #fff;
}
.icon-icon_vendor:before {
	content: '\e906';
	color: #fff;
}
.icon-icon_vehicle:before {
	content: '\e907';
	color: #fff;
}
.icon-icon_user:before {
	content: '\e908';
	color: #fff;
}
.icon-icon_schedule:before {
	content: '\e909';
	color: #fff;
}
.icon-icon_rklogo .path1:before {
	content: '\e90a';
	color: none;
}
.icon-icon_rklogo .path2:before {
	content: '\e90b';
	margin-left: -1em;
	color: rgb(108, 179, 215);
}
.icon-icon_rklogo .path3:before {
	content: '\e90c';
	margin-left: -1em;
	color: rgb(160, 205, 135);
}
.icon-icon_rklogo .path4:before {
	content: '\e90d';
	margin-left: -1em;
	color: rgb(130, 192, 105);
}
.icon-icon_rklogo .path5:before {
	content: '\e90e';
	margin-left: -1em;
	color: rgb(103, 181, 75);
}
.icon-icon_rklogo .path6:before {
	content: '\e90f';
	margin-left: -1em;
	color: rgb(47, 160, 206);
}
.icon-icon_rklogo .path7:before {
	content: '\e910';
	margin-left: -1em;
	color: rgb(0, 145, 198);
}
.icon-icon_product:before {
	content: '\e911';
	color: #fff;
}
.icon-icon_market:before {
	content: '\e912';
	color: #fff;
}
.icon-icon_jobs:before {
	content: '\e913';
	color: #fff;
}
.icon-icon_invoice:before {
	content: '\e914';
	color: #fff;
}
.icon-icon_dealer:before {
	content: '\e915';
	color: #fff;
}
.icon-icon_clients:before {
	content: '\e916';
	color: #fff;
}
.icon-tick_icon:before {
	content: '\e917';
	color: #0091c6;
}
.icon-dots_dark_icon:before {
	content: '\e918';
	color: #525a65;
}
.icon-ridekleen_logo .path1:before {
	content: '\e919';
	color: none;
}
.icon-ridekleen_logo .path2:before {
	content: '\e91a';
	margin-left: -1em;
	color: rgb(108, 179, 215);
}
.icon-ridekleen_logo .path3:before {
	content: '\e91b';
	margin-left: -1em;
	color: rgb(160, 205, 135);
}
.icon-ridekleen_logo .path4:before {
	content: '\e91c';
	margin-left: -1em;
	color: rgb(130, 192, 105);
}
.icon-ridekleen_logo .path5:before {
	content: '\e91d';
	margin-left: -1em;
	color: rgb(103, 181, 75);
}
.icon-ridekleen_logo .path6:before {
	content: '\e91e';
	margin-left: -1em;
	color: rgb(47, 160, 206);
}
.icon-ridekleen_logo .path7:before {
	content: '\e91f';
	margin-left: -1em;
	color: rgb(0, 145, 198);
}
.icon-analytics_dash_icon:before {
	content: '\e920';
	color: #34acd8;
}
.icon-analytics_dash_inactive_icon:before {
	content: '\e921';
	color: #e6e7e8;
}
.icon-admin_settings_icon:before {
	content: '\e922';
	color: #34acd8;
}
.icon-admin_settings_inactive_icon:before {
	content: '\e923';
	color: #e6e7e8;
}
.icon-preferences_inactive_icon:before {
	content: '\e924';
	color: #e6e7e8;
}
.icon-refresh_icon:before {
	content: '\e925';
	color: #757575;
}
.icon-search_icon:before {
	content: '\e926';
	color: #727272;
}
.icon-download_icon:before {
	content: '\e927';
	color: #0091c6;
}
.icon-table_columns_icon:before {
	content: '\e928';
	color: #0091c6;
}
.icon-worker_profile_icon:before {
	content: '\e929';
	color: #525a65;
}
.icon-add_icon:before {
	content: '\e92a';
	color: #0091c6;
}
.icon-chat_icon:before {
	content: '\e92b';
}
.icon-job_assigned_dark_icon:before {
	content: '\e92c';
}
.icon-inprogress_dark_icon:before {
	content: '\e92d';
}
.icon-job_completed_dark_icon:before {
	content: '\e92e';
}
.icon-redflag_dark_icon:before {
	content: '\e92f';
}
.icon-blocker_dark_icon:before {
	content: '\e930';
}
.icon-close_dark_icon:before {
	content: '\e931';
}
.icon-admin_console_icon:before {
	content: '\e933';
	color: #6d6d6d;
}
.icon-change_password_icon:before {
	content: '\e934';
	color: #6d6d6d;
}
.icon-logout_icon:before {
	content: '\e935';
	color: #6d6d6d;
}
.icon-addon_icon:before {
	content: '\e936';
}
.icon-all_service_icon:before {
	content: '\e937';
}
.icon-charging_icon:before {
	content: '\e938';
	color: #6f4c8d;
}
.icon-cleaning_icon:before {
	content: '\e939';
	color: #4c8d68;
}
.icon-edit_icon:before {
	content: '\e93a';
	color: #1779ba;
}
.icon-nested_service_icon:before {
	content: '\e93b';
}
.icon-normal_service_icon:before {
	content: '\e93c';
}
.icon-primary-service_icon:before {
	content: '\e93d';
}
.icon-rebalancing_charging_icon:before {
	content: '\e93e';
	color: #6f4c8d;
}
.icon-rebalancing_fuel_icon:before {
	content: '\e93f';
	color: #4c638d;
}
.icon-rebalancing_icon:before {
	content: '\e940';
}
.icon-refueling_icon:before {
	content: '\e941';
	color: #4c638d;
}
.icon-relocation_icon:before {
	content: '\e942';
	color: #608147;
}
