$dark_hash: #404751;
$navy_blue: #0091C6;

$primary: "blue";
$warn: #D71D37;
$gray: "gray";
$green: rgb(41, 142, 15);
$yellow: "yellow";

$light_black: #222222;
$light_gray: #525353;
$dark_gray: #3D3D3E;
$very_light_grey: #E7E8E8;
$bg-grey: #F7F8FA;
$label_font_grey: #ADADAD;



$font-extra-tiny: 10px;
$font-tiny: 12px;
$font-extra-small: 14px;
$font-small: 16px;
$font-semi-regular: 18px;
$font-regular: 20px;
$font-semi-medium: 24px;
$font-medium-30: 30px;
$font-medium: 32px;
$font-extra-medium: 36px;
$font-large-medium: 42px;
$font-large: 48px;
$font-extra-large: 60px;