body {
	min-height: 100vh;
	margin: 0;
	background: #f7f8fa 0% 0% no-repeat padding-box;
	font-family: 'Roboto', sans-serif;
	opacity: 1;
}

main {
	min-height: 100vh;
	border: 1px solid #ccc;
}

.rk-box-shadow {
	box-shadow: 0px 3px 6px #adadad29;
}

.title-box-shadow {
	box-shadow: 0px 2px 8px #adadad1c;
	border-radius: 3px;
}

.rk-border {
	border: 1px solid #e8e9eb;
}

.rk-border {
	border: 1px solid #e8e9eb;
}

.line {
	width: 45%;
	border: 1px solid #c4c4c4;
}

.height-100vh {
	min-height: 100vh;
}

.refresh-btn {
	height: 30px;
	width: 30px;
}

.lightbox-backdrop {
	z-index: 9999;
}

.liveTrackers-hover-parent {
	position: relative;

	.liveTrackers-hover-child {
		display: none;
		position: absolute;
		top: -50px;
		left: 50px;
		background-color: #fff;
		min-width: 210px;
		min-height: 100px;
		padding: 20px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		z-index: 1000;
	}

	&:hover {
		.liveTrackers-hover-child {
			display: inline-block;
		}
	}
}

// To hide download and columns section in Live tracker page
#liveTrackerDataTable_wrapper {
	.dt-buttons.button-group {
		visibility: hidden;
	}
}

// react-image-lightbox modal
.ReactModal__Overlay--after-open {
	z-index: 555555 !important;
}
