/* Font Size Styles */

.font-extra-tiny {
	font-size: $font-extra-tiny;
}

.font-tiny {
	font-size: $font-tiny;
}

.font-extra-small {
	font-size: $font-extra-small;
}

.font-small {
	font-size: $font-small;
}

.font-semi-regular {
	font-size: $font-semi-regular;
}

.font-regular {
	font-size: $font-regular;
}

.font-semi-medium {
	font-size: $font-semi-medium;
}

.font-medium {
	font-size: $font-medium;
}

.font-medium-30 {
	font-size: $font-medium-30;
}

.font-extra-medium {
	font-size: $font-extra-medium;
}

.font-large-medium {
	font-size: $font-large-medium;
}

.font-large {
	font-size: $font-large;
}

.font-extra-large {
	font-size: $font-extra-large;
}

/* Font weight styles */

.light {
	font-weight: 300;
}

.regular {
	font-weight: 400;
}

.bold {
	font-weight: 700;
}

/* Alignment rules */

.center-align {
	text-align: center;
}

.right-align {
	text-align: right;
}

.left-align {
	text-align: left;
}

.pull-left {
	float: left;
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.capitalize {
	text-transform: capitalize;
}

.primary-color {
	color: #27ae60;
}

.warn {
	color: #eb5757;
}

.success-color {
	color: green;
}

.red {
	color: #eb5757;
}

.blue-link {
	color: #1779ba;
}

.color-white {
	color: white;
}

.color-black {
	color: black;
}

.width-100 {
	width: 100%;
}

.width-200px {
	width: 200px;
}

.height-100 {
	min-height: 100vh;
}

.gr-bc {
	background-color: gray;
}

.pink-bc {
	background-color: pink;
}

.light-blue-bc {
	background-color: #e6f7ff;
}

.orange-bc {
	background-color: orange;
}

.white-bc {
	background-color: #fff;
}

.no-display {
	display: none;
}

.opacity-0 {
	opacity: 0;
}

.min-height-0 {
	min-height: 0;
}

.border-radius-50 {
	border-radius: 50%;
}

.border-0 {
	border: 0;
}

.visible-hidden {
	visibility: hidden;
}

.cursor-pointer {
	cursor: pointer;
}

.flex-even {
	flex: 1;
}

.no-wrap {
	white-space: nowrap;
}

.position-relative {
	position: relative;
}

.secondary-text-color {
	color: $navy-blue;
}

.disabled-div {
	pointer-events: none;
	opacity: 0.5;
}

.pr-10 {
	padding-right: 10px;
}

.pl-10 {
	padding-left: 10px;
}

.p-20 {
	padding: 20px;
}

.pb-0 {
	padding-bottom: 0;
}

.pb-30 {
	padding-bottom: 30px;
}

.py-10 {
	padding: 10px 0 10px 0;
}

.ml-10 {
	margin-left: 10px;
}

.ml-20 {
	margin-left: 20px;
}

.mr-10 {
	margin-right: 10px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-50 {
	margin-top: 50px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-50 {
	margin-bottom: 50px;
}

.custom-icon {
	position: relative;
	top: 1px;
	right: 5px;
	font-size: $font-semi-medium;
	opacity: 0.6;
}

.dt-custom-icon {
	width: 28px;
	display: block;
	padding-left:10px;
}

.dt-icon {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.dt-custom-icon-font{
	font-size: 41px;
}