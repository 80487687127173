.btn-service {
    width: 280px;
    max-width: 288px;
    height: 77px;
    margin: 10px 0;
    background-color: rgba(0, 145, 198, 0.07);
    border-radius: 11px !important;
    color: $light_black;
    background-color: #F5F6F6D9;

    &.active {
        background-color: $navy_blue;
        color: #fff;

        .btn-service__count {
            color: $light_black;
        }
    }
}

.btn-service__count {
    width: 64px;
    height: 37px;
    border-radius: 8px;
    @extend .white-bc;
    box-shadow: 0px 2px 8px #9F9F9F47;
}

.btn-service__label {
    max-width: 130px;
}