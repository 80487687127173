.btn-jobs {
    position: relative;
    height: 45px;
    min-width: 130px;
    max-width: 150px;
    background-color: rgba(0, 145, 198, 0.07);
    border-radius: 10px !important;
    color: $light_black;

    &.active {
        background-color: $navy_blue;
        color: #fff;
    }
}

.btn-jobs__count {
    position: absolute;
    right: 0;
    top: -15px;
    min-width: 32px;
    height: 23px;
    padding: 0 10px;
    border-radius: 14px;
    box-shadow: 0px 2px 9px #00000036;
    color: $navy_blue;
    @extend .white-bc
}