table.dataTable {
	width: 100% !important;
	margin: 0.5em 0 0 !important;
	min-height: 200px;

	.even.selected {
		background-color: #008cba;
	}

	thead {
		tr {
			th {
				@extend .center-align;
				@extend .uppercase;
			}
		}
	}

	tbody {
		td {
			&:focus {
				outline: none;
			}
		}

		td,
		th {
			padding: 0.5rem 0.625rem;
		}

		tr {
			border-bottom: 1px solid rgba(112, 112, 112, 0.5);
			font-size: 13px;

			&:nth-child(even) {
				background-color: #fff;
			}
		}
	}
}

div.dataTables_wrapper {
	// margin-top: 20px;

	div.dt-buttons {
		float: right;
		margin-left: 10px;
	}

	.button-group {
		margin-bottom: 0;

		.button {
			padding: 0.45em 0.2em;
		}
	}

	div.dataTables_filter {
		label {
			position: absolute;
			top: -7px;
			right: 155px;

			.icon-search_icon {
				position: relative;
				left: 21px;
			}
		}

		input {
			border: none;
			background-color: $bg-grey;
			border-bottom: 1px solid #cacaca;
			box-shadow: none;
			padding-left: 20px;
		}

		.anticon-search {
			position: relative;
			left: 20px;
		}
	}

	.top {
		position: relative;
		bottom: 10px;

		.dt-buttons {
			.buttons-colvis,
			.dropdown {
				background-color: white;
				color: #1779ba;
				border: 2px solid #0091c62b;
				border-radius: 5px;
				padding: 5px;

				&::after {
					display: none;
				}
			}

			.buttons-colvis {
				margin-left: 8px;
			}
		}

		.dt-button-collection {
			right: 300px !important;
			left: unset !important;
		}
	}

	table {
		thead {
			background: #edeeee 0% 0% no-repeat padding-box;
			border-radius: 10px;
		}
	}

	.bottom {
		border: 1px solid #e5e6e6;
		border-radius: 0 0 11px 11px;
		padding: 20px 10px 50px 10px;
		background: white;
		font-size: 14px;

		.dataTables_info {
			width: 40%;
			float: left;
		}

		.dataTables_length {
			position: relative;
			top: -7px;
		}

		.dataTables_paginate {
		}
	}
}

/**
* Datatable default css override the page css, to stop that below code is used
*/
input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
	background-color: transparent;
}

table tbody tr:nth-child(even) {
	background-color: transparent;
}
