.login-box {
	padding: 10px;
	border-radius: 10px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.login-btn {
	margin-top: 15px;
	width: 100%;
	height: 40px;
	border-radius: 5px;
	font-size: 16px;
}

.change-password-form {
	.ant-input-password {
		display: flex;
		justify-content: center;
		align-items: center;
		// padding: 20px 10px;

		input {
			box-shadow: none;
		}
	}
}
