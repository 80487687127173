.info-box {
    @extend .rk-box-shadow;
    @extend .white-bc;
    border-radius: 7px;
    padding: 0px 15px;
    margin-bottom: 10px;
    transition: box-shadow .3s;

    &:hover {
        box-shadow: 0px 5px 15px #ADADAD29;
    }
}

.info-box--small {
    height: 30px;
}

.info-box__title {
    @extend .bold;
    @extend .uppercase;
    @extend .font-tiny;
    //min-width: 105px;
    color: $dark_gray;
    opacity: 0.67;
}

.info-box__date {
    color: $dark_gray;
    opacity: 0.67;
    @extend .font-tiny;
}

.info-box__date_position {
    color: grey;
    opacity: 0.67;
    @extend .font-tiny;
    position: absolute;
    top: 4px;
    background-color: $very_light_grey;
    border-radius: 5px;
    .to-text {
        background-color: white;
        border-radius: 10px;
        padding: 3px 7px;
    }
}

.info-box__warn {
    min-width: 140px;
    @extend .bold;
    //color: $green;
    @extend .font-semi-regular;
}