.year-to-today {
    @extend .rk-box-shadow;

    .btn {
        @extend .rk-border;
        @extend .white-bc;
        color: #52535378;

        &:hover {
            cursor: pointer;
        }
    }
}