.ant-layout {
	//background-color: white;

	.ant-layout-content {
		border: 0;
		//padding: 20px;
		background: #f7f8fa 0% 0% no-repeat padding-box;
	}

	.ant-page-header {
		padding: 0;
		.ant-page-header-heading-title {
			font-size: 32px;
		}
	}
}

.ant-form-item {
	label {
		top: 10px;
	}
	.ant-calendar-picker {
		min-width: 300px !important;
	}
}

.search-label {
	.ant-form-item-label {
		width: 100%;
		text-align: left;
		label {
			top: 5px;
			color: rgba(142, 142, 142, 1);
		}
	}
}

.ant-card {
	box-shadow: 0px 1px 11px #00000017;
	.label-text {
		color: $label_font_grey;
	}
}

.ant-select-dropdown-menu {
	margin-left: 0;
}

.ant-btn-text{
	white-space: pre;
	text-align: left;
}

.selected_btn_active{
	color: #40a9ff;
	border-color: #40a9ff;
}

.sel_default{
	color:'#000';
	border-color:#d9d9d9;
}

.jobs_table tbody tr td{
	padding: 0px 0px;
}

.services_table{
	white-space: pre;
}

.timeslot .ant-checkbox + span{
	white-space: break-spaces;
}

.disabled .ant-checkbox-checked .ant-checkbox-inner{
	background-color: #d9d9d9;
	border-color:#d9d9d9;
}

.service_form .ant-form-item{
	margin-bottom: 4px;
}

.discount_form .ant-form-item{
	margin-bottom: 15px;
}

.discount .ant-form-item-control-input{
	top:-10px;
}

.jobs_form_with_multi .ant-table-selection{
	opacity: 0;
}

.jobs_form_with_multi .ant-table-selection-column{
	width: 3%;
}

.jobs_table .ant-table-selection-column{
	width: 3%;
}

.services .dt-custom-icon{
	padding-left: 0px;
	padding-top: 5px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
	padding: 16px 12px;
}

.ant-picker-calendar-full .ant-picker-panel{
	padding: 0px 50px;
}

.ant-picker-calendar-full thead{
	background: transparent;
	border: none;
	border-bottom: 1px solid #f0f0f0;
	
}


.ant-picker-date-panel{
	border: 1px solid #f0f0f0 !important;;
}

.ant-picker-cell-disabled{
	color: #000;
	background-color: #d9d9d9;
	margin: 5px;
	opacity: 0.6;
}

// .ant-picker-cell-disabled  input[type=checkbox] {
// 	visibility: hidden;
// } 

.ant-typography-edit{
	float: right;
}