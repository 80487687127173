.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;

  img {
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
  }
}

.image-box:hover img {
  transform: scale(1.1);
}