.modal-dialog {
    .without-headersection {
        position: absolute;
        right: 20px;
        top: 10px;
        z-index: 999;
    }
    &.market-preferences-modal {
        .modal-header {
            border-bottom: none;
            .modal-title {
                padding-left:  1em;
                font-weight: bold;
            }
        }

        .modal-body {
            padding-top: 0;
        }

        max-width: 1024px;
        .modal-content {
            height: calc(100vh - 70px);
        }

        .market-preference-market-list {
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 410px;
        }
        .market-preference-form-sec {
            overflow-y: scroll;
            overflow-x: hidden;
            //max-height: 410px;
            min-height: calc(100vh - 250px);
            max-height: calc(100vh - 250px);
        }
        h5 { 
            width:100%; 
            border-bottom: 1px solid #DEE2E6; 
            line-height:0.1em; margin:10px 0 20px; 
            span { 
                background:#fff; 
                padding-right: 10px; 
            }
        } 

        .clear-btn {
            position: relative;
            top: 32px;
            color: $navy_blue;
        }

        .btn-section {
            position: absolute;
            bottom: -45px;
            right: 30px;
        }

        .nav-tabs .nav-link {
            border: 0;
            border-bottom: 3px solid $navy_blue;
            color: $navy_blue;
        }

        .btn {
            border-radius: .25rem;
        }
    }


    .settings-sidebar {
        .list-group-item {
            border: none;
            border-radius: .25rem;
        }
    }
}
