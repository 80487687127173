.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	width: 50px;
	min-height: 100vh;
	z-index: 2;
	background: #404751 0% 0% no-repeat padding-box;

	.nav-link {
		padding: 0.5rem 0;
		text-align: center;
	}

	.dropdown {
		button.btn {
			color: $navy_blue;
		}
		.dropdown-toggle::after {
			display: contents;
		}
	}
}

.ant-menu-item-active, .ant-menu-submenu-selected {
    .custom-icon {
        opacity: 1;
    }
}

// to hide custom icons on sidebar menu collapsed
.ant-menu-inline-collapsed {
	.custom-icon + span {
		display: inline-block;
		max-width: 0;
		opacity: 0;
	}
}
