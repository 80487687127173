.time-filter__group {
    @extend .rk-box-shadow;
    @extend .rk-border;
    @extend .cursor-pointer;
    min-width: 225px;
    height: 45px;
    background-color: white;
    border-radius: 25px;
    text-align: center;
    margin: 0 15px;
}

.time-filter__btn {
    height: 45px;
    width: 45px;
    border-radius: 7px;
    background-color: white;
}

.time-filter__category {
    @extend .uppercase;
    @extend .bold;
    font-size: 13px;
    color: $light_black;
}

.time-filter__date_range {
    color: $light_gray;
    font-size: 15px;
    opacity: 0.67;
}