.chart-box {
    @extend .info-box
}

.chart-box--min-height {
    min-height: 570px;
}

.chart-box__line {
    border-top: 1px solid red;
    // display: block;
    // height: 1px;
    // border: 0;
    // border-top: 1px solid #ccc;
    // margin: 1em 0;
    // padding: 0;
}

.chart-legend-box {
    width: 20px;
    height: 20px;
}