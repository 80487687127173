.selected-jobs-list {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 115px;
    white-space: nowrap;

    .ant-card {
        display: inline-block;
        margin-right: 10px;
        width: 280px;
        background: #EBF6FA 0% 0% no-repeat padding-box;
        // float: left;
        .ant-card-body {
            padding: 10px;
            .remove {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #FFFFFF;
                opacity: 1;
                padding: 0 5px;
                border-radius: 50px;
                font-size: 13px;
                color: $navy_blue;
            }
        }
        .remove {
            position: absolute;
            cursor: pointer;
            right: 10px;
        }
    }
}

.job-heading {
    padding: 5px;
    background-color: #adadad59;
    font-weight: 500;
}

.information-circle {
    padding: 0 5px;
    background-color: #000000;
    font-weight: 500;
    border-radius: 20px;
    color: white;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
}